<main role="main" class="container-fluid" aria-label="Ticketantrag">
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="frame frame-full">
        <div id="edit-antragTicket" class="content scaffold-edit" role="main" aria-label="Formular">
          <div class="row justify-content-between">
            <!--anfang: statusmeldung-->
            <div class="col-md-2">
              <div class="status-display">

                <div class="pb-3">
                  <div class="id-info text-break">
                    <h4 class="m-2"
                        *ngIf="antragTicketId && antragTicketId !== undefined && antragTicketId !== null; else newAntragTicket">
                      {{getTextbausteinOrTranslate(KeyString.ANTRAGTICKET_ID_NAME_ASM, 'TICKETDETAIL.ANTRAGTICKET_ID_NAME_ASM')}}
                      <div class="text-end default-text-size" data-cy="antragticket-id">{{ this.antragTicketId }}</div>
                    </h4>
                    <ng-template #newAntragTicket>
                      <h4 class="m-2">
                        {{getTextbausteinOrTranslate(KeyString.SUBTITLE_TICKET,'TICKETDETAIL.SUBTITLE_TICKET')}}
                      </h4>
                    </ng-template>
                  </div>
                </div>

                <h4 class="text-break" *ngIf="this.postkorbReference && this.postkorbReference !== '' && this.postkorbReference !== null">
                  {{ "TICKETDETAIL.POSTKORB_REFERENCE" | translate }} {{ postkorbReference }}
                </h4>

                <h4 class="text-break" *ngIf="antragTicket?.status == draftStatus || !antragTicketId">
                  {{"TICKETDETAIL.ANTRAGSTATUS" | translate}}
                </h4>
                <div *ngIf="antragTicket?.status == draftStatus || !antragTicketId" class="alert alert-info antrag-status text-break" role="alert">
                  <p><fa-icon [icon]="faInfo"></fa-icon>
                    {{"TICKETDETAIL.STATUS.ENTWURF" | translate}}
                  </p>
                  <span *ngIf="!antragTicketId" class="alert warning"> {{('STANDARD.NOT_SAVED'|translate)}} </span>
                </div>
                <div *ngIf="antragTicket?.antragsdatum !== null" class="alert alert-info antrag-status text-break" role="alert">
                  <p><i class="fa fa-info-circle"></i>
                    {{"TICKETDETAIL.STATUS.EINGEREICHT" | translate}} {{antragTicket?.antragsdatum | date:'dd.MM.yyyy'}}.
                  </p>
                </div>
                <div *ngIf="antragTicket?.bestelldatum !== null" class="alert alert-info antrag-status text-break" role="alert">
                  <p><i class="fa fa-info-circle"></i>
                    {{"TICKETDETAIL.STATUS.BESTELLT" | translate}} {{antragTicket?.bestelldatum | date:'dd.MM.yyyy'}}.
                  </p>
                </div>
                <div *ngIf="antragTicket?.ausgabedatum !== null" class="alert alert-info antrag-status text-break" role="alert">
                  <p><i class="fa fa-info-circle"></i>
                    {{"TICKETDETAIL.STATUS.AUSGEGEBEN" | translate}} {{antragTicket?.ausgabedatum | date:'dd.MM.yyyy'}}.
                  </p>
                </div>
              </div>
            </div>
            <!--ende: statusmeldung-->

            <!--anfang: form container-->
            <div class="col-md-10">
              <div class="well well-lg">
                <form [formGroup]="antragTicketForm" id="antragForm"
                      class="form-horizontal form-antragTicket" accept-charset="UTF-8">
                  <div class="row" *ngIf="benutzer">
                    <fieldset>
                      <legend>
                        {{getTextbausteinOrTranslate(KeyString.BEANTRAGENDEPERSON_TITEL_TICKET,'TICKETDETAIL.BEANTRAGENDE')}}
                      </legend>
                      <div class="col-md-2"></div>
                      <div class="col-md-6">
                        <p>{{benutzer.vorname}} {{benutzer.name}}, {{benutzer.geburtsdatum}}, {{benutzer.strasse}}, {{benutzer.plz}} {{benutzer.ort}}, {{benutzer.schule?.name}}</p>
                      </div>
                    </fieldset>
                  </div>
                  <!--auswahl antragsteller-->
                  <ng-container *ngIf="!this.readonly">
                    <hr>
                    <div class="row align-items-end mb-0">
                      <div class="col-md-3 mt-0 mb-0">
                        <app-custom-select id="schuelerId"
                                           [customFormGroup]="antragTicketForm"
                                           placeholder="-"
                                           [items]="schuelerList"
                                           bindLabel="fullName"
                                           bindValue="id"
                                           [multiple]="false"
                                           [clearable]="false"
                                           [label]="''+(this.readonly? '':this.pflichtFeldMarker)+'Ich stelle diesen Antrag für ..'"
                                           [readonly] = readonly
                                           (onchange)="checkIfVorgaengerExists()"
                                           data-cy="schuelerId"
                        ></app-custom-select>
                      </div>
                      <div class="col-md-2 mt-0 mb-3" >
                        <input type="button"
                               class="btn btn-secondary btn-add-student"
                               value="{{'SCHUELER-TABLE.ADD_BUTTON'|translate}}"
                               title="{{'SCHUELER-TABLE.ADD_BUTTON'|translate}}"
                               (click)="addSchueler()"
                               data-cy="add-schueler-top"
                        >
                      </div>
                    </div>
                    <div class="row form-text text-muted ms-1 mt-0 mb-3">
                      {{'TICKETDETAIL.MICH_SELBST_INFO'|translate}}
                    </div>
                  </ng-container>
                  <!--anfang: Formular-->
                  <hr>
                  <div class="row">

                    <!--LINKE SEITE-->
                    <div class="col-md-6">

                      <!--anfang: Antragsteller-->

                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('name', fieldConfig)?.visible"
                           class="form-group group-for-name col mt-0 mb-0"
                      >
                      <div class="d-flex flex-row">
                        <div class="flex-grow-1 mr-2">
                          <app-custom-input id="name"
                                            [customFormGroup]="antragTicketForm"
                                            [label]="getFieldLabelOrTranslate('name','STANDARD.NAME')"
                                            [infoText]="getTextbausteinOrTranslate(KeyString.NAME_INFO,'STANDARD.CHANGE_IN_PROFILE')"
                                            [readonly]="true"
                                            data-cy="name"
                          ></app-custom-input>
                        </div>
                      </div>
                    </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vorname', fieldConfig)?.visible"
                           class="form-group group-for-vorname col mt-0 mb-0"
                      >
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2" >
                            <app-custom-input id="vorname"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="getFieldLabelOrTranslate('vorname','STANDARD.VORNAME')"
                                              [infoText]="getTextbausteinOrTranslate(KeyString.VORNAME_INFO,'STANDARD.CHANGE_IN_PROFILE')"
                                              [readonly]="true"
                                              data-cy="vorname"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('strasse', fieldConfig)?.visible"
                           class="form-group group-for-strasse col mt-0 mb-0">
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="strasse"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="getFieldLabelOrTranslate('strasse','STANDARD.STRASSE')"
                                              [infoText]="getTextbausteinOrTranslate(KeyString.STRASSE_INFO,'STANDARD.CHANGE_IN_PROFILE')"
                                              [readonly]="true"
                                              noteText="{{ 'STANDARD.STRASSE_NOTE'|translate }}"
                                              data-cy="strasse"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('plz', fieldConfig)?.visible"
                           class="form-group group-for-plz col mt-0 mb-0"
                      >
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="plz"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="getFieldLabelOrTranslate('plz','STANDARD.PLZ')"
                                              [infoText]="getTextbausteinOrTranslate(KeyString.PLZ_INFO,'STANDARD.CHANGE_IN_PROFILE')"
                                              [readonly]="true"
                                              (onchange)="setOrt($event, 'ort')"
                                              data-cy="plz"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('ort', fieldConfig)?.visible"
                           class="form-group group-for-ort col mt-0 mb-0">
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="ort"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="getFieldLabelOrTranslate('ort','STANDARD.WOHNORT')"
                                              [infoText]="getTextbausteinOrTranslate(KeyString.WOHNORT_INFO,'STANDARD.CHANGE_IN_PROFILE')"
                                              [readonly]="true"
                                              data-cy="ort"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('adresszusatz', fieldConfig)?.visible"
                           class="form-group group-for-adresszusatz col mt-0 mb-0">
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="adresszusatz"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="getFieldLabelOrTranslate('adresszusatz','STANDARD.ADRESSZUSATZ')"
                                              [infoText]="getTextbausteinOrTranslate(KeyString.ADRESSZUSATZ_INFO,'STANDARD.ADRESSZUSATZ_INFO')"
                                              [readonly]="readonly"
                                              data-cy="adresszusatz"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('land', fieldConfig)?.visible"
                           class="form-group group-for-land col mt-0 mb-0">
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-select
                              id="land"
                              [customFormGroup]="antragTicketForm"
                              [items]="landList"
                              [multiple]="false"
                              [clearable]="false"
                              bindLabel="value"
                              bindValue="name"
                              [label]="getFieldLabelOrTranslate('land','STANDARD.LAND')"
                              [infoText]="getTextbausteinOrTranslate(KeyString.LAND_INFO,'')"
                              [readonly]="true"
                              data-cy="land"
                            ></app-custom-select>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geburtsdatum', fieldConfig)?.visible"
                           class="form-group group-for-geburtsdatum col mt-0 mb-0"
                      >
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="geburtsdatum"
                                              type="date"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="getFieldLabelOrTranslate('geburtsdatum','STANDARD.GEBURTSDATUM')"
                                              [infoText]="getTextbausteinOrTranslate(KeyString.GEBURTSDATUM_INFO,'STANDARD.CHANGE_IN_PROFILE')"
                                              [readonly]="true"
                                              data-content="dd.mm.yyyy"
                                              [max]="this.today | date:'yyyy-MM-dd'"
                                              [min]="this.minDateBirthday | date:'yyyy-MM-dd'"
                                              (onchange)="vormundChange()"
                                              data-cy="geburtsdatum"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geschlecht', fieldConfig)?.visible"
                           class="form-group group-for-geschlecht  col mt-0 mb-0">
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-select id="geschlecht"
                                               [customFormGroup]="antragTicketForm"
                                               placeholder="-"
                                               [items]="optionGeschlecht"
                                               [multiple]="false"
                                               [clearable]="false"
                                               bindLabel="name"
                                               bindValue="id"
                                               [label]="getFieldLabelOrTranslate('geschlecht','STANDARD.GESCHLECHT.TITEL')"
                                               [infoText]="getTextbausteinOrTranslate(KeyString.GESCHLECHT_INFO,'STANDARD.GESCHLECHT_INFO')"
                                               [readonly]="readonly"
                                               data-cy="geschlecht"
                            ></app-custom-select>
                          </div>
                        </div>
                      </div>
                      <div  *ngIf="fieldConfigService.getFieldFromFieldConfig('email', fieldConfig)?.visible"
                            class="form-group group-for-email col mt-0 mb-0">
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="email"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="getFieldLabelOrTranslate('email','STANDARD.EMAILADRESSE')"
                                              [infoText]="getTextbausteinOrTranslate(KeyString.EMAIL_INFO,'STANDARD.EMAIL_INFO')"
                                              [readonly]="readonly"
                                              data-cy="email"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="effectivelyVisible.includes('emailSchueler') && fieldConfigService.getFieldFromFieldConfig('emailSchueler', fieldConfig)?.visible"
                           class="form-group group-for-name col mt-0 mb-0"
                      >
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="emailSchueler"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="getFieldLabelOrTranslate('emailSchueler','STANDARD.EMAILADRESSE-SCHUELER')"
                                              [infoText]="getTextbausteinOrTranslate(KeyString.EMAIL_SCHUELER_INFO,'STANDARD.EMAIL_INFO')"
                                              [readonly]="readonly"
                                              data-cy="emailSchueler"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="effectivelyVisible.includes('telefonnummer') && fieldConfigService.getFieldFromFieldConfig('telefonnummer', fieldConfig)?.visible"
                           class="form-group group-for-name col mt-0 mb-0"
                      >
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="telefonnummer"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="getFieldLabelOrTranslate('telefonnummer','STANDARD.TELEFONNUMMER-SCHUELER')"
                                              [infoText]="getTextbausteinOrTranslate(KeyString.TELEFONNUMMER_INFO,'STANDARD.TELEFONNUMMER_INFO')"
                                              [readonly]="readonly"
                                              [noteText]="translateService.instant('STANDARD.TELEFONNUMMER_VALIDATION_INFO')"
                                              data-cy="telefonnummer"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>

                      <!--anfang: Vormund-->
                      <div *ngIf="(isSchuelerUnderAge || isAntragstellerUnderage) && !allVormundFieldsInvisible"
                           class="flex-row sub-paragraph"
                      >
                        <h3>
                          {{getTextbausteinOrTranslate(KeyString.VORMUND_TITLE_TICKET,'STANDARD.VORMUND')}}
                        </h3>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vormundName', fieldConfig)?.visible"
                             class="form-group group-for-vormundName col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundName"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('vormundName','STANDARD.NAME')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDNAME_INFO,'')"
                                                [readonly]="readonly"
                                                data-cy="vormundName"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vormundVorname', fieldConfig)?.visible"
                             class="form-group group-for-vormundVorname  col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundVorname"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('vormundVorname','STANDARD.VORNAME')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDVORNAME_INFO,'')"
                                                [readonly]="readonly"
                                                data-cy="vormundVorname"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('isVormundadresseAbweichend', fieldConfig)?.visible"
                             class="form-group group-for-name col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2"
                                 [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                              <label class="checkbox-container"  style="margin-bottom:3ch;">
                                {{'FAHRKOSTEN-DETAIL.VORMUNDADRESSE-ABWEICHEND' | translate}}
                                <input type="checkbox" class="control control-checkbox custom-control-input"
                                       formControlName="isVormundadresseAbweichend"
                                       id="isVormundadresseAbweichend"
                                       name="isVormundadresseAbweichend"
                                >
                                <span class="checkmark" data-cy="isVormundadresseAbweichend"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundStrasse')"
                             class="form-group group-for-vormundStrasse  col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundStrasse"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('vormundStrasse','STANDARD.STRASSE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDSTRASSE_INFO,'')"
                                                [readonly]="readonly"
                                                noteText="Bitte geben Sie die Straße und die Hausnummer an."
                                                data-cy="vormundStrasse"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundPlz')"
                             class="form-group group-for-vormundPlz col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundPlz"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('vormundPlz','STANDARD.PLZ')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDPLZ_INFO,'')"
                                                [readonly]="readonly"
                                                (onchange)="setOrt($event, 'vormundOrt')"
                                                [maxlength]="getMaxLength('vormundPlz')"
                                                [pattern]="getPattern('vormundPlz')"
                                                data-cy="vormundPlz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundOrt')"
                             class="form-group group-for-vormundOrt col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundOrt"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('vormundOrt','STANDARD.WOHNORT')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDORT_INFO,'')"
                                                [readonly]="readonly"
                                                data-cy="vormundOrt"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundAdresszusatz')"
                             class="form-group group-for-vormundAdresszusatz col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundAdresszusatz"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('vormundAdresszusatz','STANDARD.ADRESSZUSATZ')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDADRESSZUSATZ_INFO,'STANDARD.ADRESSZUSATZ_INFO')"
                                                [readonly]="readonly"
                                                data-cy="vormundAdresszusatz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('vormundLand')"
                             class="form-group group-for-vormundLand col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                             <app-custom-select
                                id="vormundLand"
                                [customFormGroup]="antragTicketForm"
                                [items]="landList"
                                [multiple]="false"
                                [clearable]="false"
                                bindLabel="value"
                                bindValue="name"
                                [label]="getFieldLabelOrTranslate('vormundLand','STANDARD.LAND')"
                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDLAND_INFO,'STANDARD.LAND_INFO')"
                                [readonly]="readonly"
                                (onchange)="this.setPLZValidatorForControl('vormundPlz')"
                                data-cy="vormundLand"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vormundGeburtsdatum', fieldConfig)?.visible"
                             class="form-group group-for-vormundGeburtsdatum col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundGeburtsdatum"
                                                type="date"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('vormundGeburtsdatum','STANDARD.GEBURTSDATUM')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDGEBURTSTAG_INFO,'STANDARD.GEBURTSDATUM_VORMUND_INFO')"
                                                [readonly]="readonly"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.maxDateVormundGeburtsdatum | date:'yyyy-MM-dd'"
                                                [min]="this.minDateBirthday | date:'yyyy-MM-dd'"
                                                data-cy="vormundGeburtsdatum"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vormundGeschlecht', fieldConfig)?.visible"
                             class="form-group group-for-vormundGeschlecht col mt-0 mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="vormundGeschlecht"
                                                 [customFormGroup]="antragTicketForm"
                                                 placeholder="-"
                                                 [items]="optionGeschlecht"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('vormundGeschlecht','STANDARD.GESCHLECHT.TITEL')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDGESCHLECHT_INFO,'STANDARD.GESCHLECHT_INFO')"
                                                 [readonly]="readonly"
                                                 data-cy="vormundGeschlecht"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vormundEmail', fieldConfig)?.visible"
                             class="form-group group-for-vormundEmail col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundEmail"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('vormundEmail','STANDARD.EMAIL')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDEMAIL_INFO,'STANDARD.EMAIL_INFO')"
                                                [readonly]="readonly"
                                                data-cy="vormundEmail"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vormundTelefonnummer', fieldConfig)?.visible"
                             class="form-group group-for-vormundTelefonnummer  col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vormundTelefonnummer"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('vormundTelefonnummer','STANDARD.TELEFONNUMMER')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORMUNDTELEFONNUMMER_INFO,'STANDARD.TELEFONNUMMER_INFO')"
                                                [readonly]="readonly"
                                                [noteText]="translateService.instant('STANDARD.TELEFONNUMMER_VALIDATION_INFO')"
                                                data-cy="vormundTelefonnummer"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende: Vormund-->

                      <!--weiter: Antragsteller-->
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('typ', fieldConfig)?.visible"
                           class="form-group group-for-typ col mt-0 mb-0"
                      >
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-select id="typ"
                                               [customFormGroup]="antragTicketForm"
                                               placeholder="-"
                                               [items]="optionTyp"
                                               [multiple]="false"
                                               [clearable]="false"
                                               bindLabel="name"
                                               bindValue="id"
                                               [label]="getFieldLabelOrTranslate('typ','TICKETDETAIL.ANTRAGSART')"
                                               [infoText]="getTextbausteinOrTranslate(KeyString.ANTRAGSART_INFO,'TICKETDETAIL.TYP_INFO')"
                                               [readonly]="readonly"
                                               data-cy="typ"
                            ></app-custom-select>
                          </div>
                        </div>
                      </div>

                      <!--anfang kostenberechnung info-->
                      <div class="flex-row sub-paragraph">

                        <h4 class="mt-1 mb-3">
                          {{getTextbausteinOrTranslate(KeyString.INFO_KOSTENBERECHNUNG_TITEL_TICKET,'TICKETDETAIL.INFORMATIONEN_KOSTENBERECHNUNG')}}
                        </h4>

                        <div
                          *ngIf="
                          fieldConfigService.getFieldFromFieldConfig('geschwisterkind1', fieldConfig)?.visible
                          || fieldConfigService.getFieldFromFieldConfig('geschwisterkind2', fieldConfig)?.visible
                          || fieldConfigService.getFieldFromFieldConfig('geschwisterkind3', fieldConfig)?.visible
                          || fieldConfigService.getFieldFromFieldConfig('geschwisterkind4', fieldConfig)?.visible
                          || fieldConfigService.getFieldFromFieldConfig('geschwisterkind5', fieldConfig)?.visible
                          "
                           class="col mt-0 mb-3"
                        >
                          <div  *ngIf="!this.readonly">
                            <input type="button"
                                   class="btn btn-secondary btn-add-student w-100"
                                   value="{{'SCHUELER-TABLE.ADD_BUTTON_PLURAL'|translate}}"
                                   title="{{'SCHUELER-TABLE.ADD_BUTTON_PLURAL'|translate}}"
                                   (click)="addSchueler()"
                                   data-cy="add-schueler-geschwister"
                            >
                          </div>
                        </div>

                        <!--anfang geschwisterkind1-->
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geschwisterkind1', fieldConfig)?.visible"
                             class="form-group group-for-geschwisterkind1 col mt-0 mb-3"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="geschwisterkind1"
                                                 [customFormGroup]="antragTicketForm"
                                                 placeholder="-"
                                                 [items]="schuelerList"
                                                 [multiple]="false"
                                                 bindLabel="fullName"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('geschwisterkind1','STANDARD.GESCHWISTERKIND1')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.GESCHWISTERKIND1_INFO,'TICKETDETAIL.GESCHWISTERKIND_INFO')"
                                                 [readonly]="readonly"
                                                 (onchange) = setGeschwisterkind(1)
                                                 data-cy="geschwisterkind1"
                              ></app-custom-select>
                            </div>
                          </div>
                          <div *ngIf=" !(!getFormItem('geschwisterkind1').value) && fieldConfigService.getFieldFromFieldConfig('geschwisterkind1', fieldConfig)?.visible"
                               class="row"
                          >
                            <span>
                              <div class="geschwisterInput-label">Name:</div>
                              <input id="geschwisterkind1name" type="text"
                                     formControlName="geschwisterkind1name"
                                     class="geschwisterInput" readonly="readonly"
                                     [style]="dynamicWidth('geschwisterkind1name')"
                              />,
                              <input id="geschwisterkind1vorname" type="text"
                                     formControlName="geschwisterkind1vorname"
                                     class="geschwisterInput" readonly="readonly"
                                     [style]="dynamicWidth('geschwisterkind1vorname')"
                              />
                            </span>
                            <br>
                            <span>
                              <div class="geschwisterInput-label">Geburtsdatum:</div>
                              <input id="geschwisterkind1geburtsdatum" type="text"
                                     formControlName="geschwisterkind1geburtsdatum"
                                     class="geschwisterInput" readonly="readonly"
                                     [style]="dynamicWidth('geschwisterkind1geburtsdatum')"
                              />
                            </span>
                            <br>
                            <span>
                              <div class="geschwisterInput-label">Adresse:</div>
                              <input type="text" id="geschwisterkind1strasse"
                                     formControlName="geschwisterkind1strasse"
                                     class="geschwisterInput" readonly="readonly"
                                     [style]="dynamicWidth('geschwisterkind1strasse')"
                              />,
                              <input type="text" id="geschwisterkind1plz"
                                     formControlName="geschwisterkind1plz"
                                     class="geschwisterInput" readonly="readonly"
                                     [style]="dynamicWidth('geschwisterkind1plz')"
                              />
                              <input type="text" id="geschwisterkind1ort"
                                     formControlName="geschwisterkind1ort"
                                     class="geschwisterInput" readonly="readonly"
                                     [style]="dynamicWidth('geschwisterkind1ort')"
                              />
                            </span>
                            <br>
                            <span>
                              <div class="geschwisterInput-label">Schule:</div>
                              <input type="text" id="geschwisterkind1schule"
                                     formControlName="geschwisterkind1schule"
                                     class="geschwisterInput" readonly="readonly"
                                     [style]="dynamicWidth('geschwisterkind1schule')"
                              />
                            </span>
                          </div>
                        </div>
                        <!--ende geschwisterkind1-->

                        <!--anfang geschwisterkind2-->
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geschwisterkind2', fieldConfig)?.visible"
                             class="form-group group-for-geschwisterkind2  col mt-0 mb-3">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="geschwisterkind2"
                                                 [customFormGroup]="antragTicketForm"
                                                 placeholder="-"
                                                 [items]="schuelerList"
                                                 bindLabel="fullName"
                                                 [multiple]="false"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('geschwisterkind2','STANDARD.GESCHWISTERKIND2')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.GESCHWISTERKIND2_INFO,'TICKETDETAIL.GESCHWISTERKIND_INFO')"
                                                 [readonly]="readonly"
                                                 (onchange) = setGeschwisterkind(2)
                                                 data-cy="geschwisterkind2"
                              ></app-custom-select>
                            </div>
                          </div>

                          <div *ngIf=" !(!getFormItem('geschwisterkind2').value) && fieldConfigService.getFieldFromFieldConfig('geschwisterkind2', fieldConfig)?.visible"
                               class="row"
                          >
                              <span>
                                <div class="geschwisterInput-label">Name:</div>
                                <input id="geschwisterkind2name" type="text"
                                       formControlName="geschwisterkind2name"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind2name')"
                                />,
                                <input id="geschwisterkind2vorname" type="text"
                                       formControlName="geschwisterkind2vorname"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind2vorname')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Geburtsdatum:</div>
                                <input id="geschwisterkind2geburtsdatum" type="text"
                                       formControlName="geschwisterkind2geburtsdatum"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind2geburtsdatum')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Adresse:</div>
                                <input type="text" id="geschwisterkind2strasse"
                                       formControlName="geschwisterkind2strasse"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind2strasse')"
                                />,
                                <input type="text" id="geschwisterkind2plz"
                                       formControlName="geschwisterkind2plz"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind2plz')"
                                />
                                <input type="text" id="geschwisterkind2ort"
                                       formControlName="geschwisterkind2ort"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind2ort')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Schule:</div>
                                <input type="text" id="geschwisterkind2schule"
                                       formControlName="geschwisterkind2schule"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind2schule')"
                                />
                              </span>
                          </div>
                        </div>
                        <!--ende geschwisterkind2-->

                        <!--anfang geschwisterkind3-->
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geschwisterkind3', fieldConfig)?.visible"
                             class="form-group group-for-geschwisterkind3  col mt-0 mb-3"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="geschwisterkind3"
                                                 [customFormGroup]="antragTicketForm"
                                                 placeholder="-"
                                                 [items]="schuelerList"
                                                 bindLabel="fullName"
                                                 [multiple]="false"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('geschwisterkind3','STANDARD.GESCHWISTERKIND3')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.GESCHWISTERKIND3_INFO,'TICKETDETAIL.GESCHWISTERKIND_INFO')"
                                                 [readonly]="readonly"
                                                 (onchange) = setGeschwisterkind(3)
                                                 data-cy="geschwisterkind3"
                              ></app-custom-select>
                            </div>
                          </div>

                          <div *ngIf=" !(!getFormItem('geschwisterkind3').value) && fieldConfigService.getFieldFromFieldConfig('geschwisterkind3', fieldConfig)?.visible"
                               class="row"
                          >
                              <span>
                                <div class="geschwisterInput-label">Name:</div>
                                <input id="geschwisterkind3name" type="text"
                                       formControlName="geschwisterkind3name"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind3name')"
                                />,
                                <input id="geschwisterkind3vorname" type="text"
                                       formControlName="geschwisterkind3vorname"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind3vorname')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Geburtsdatum:</div>
                                <input id="geschwisterkind3geburtsdatum" type="text"
                                       formControlName="geschwisterkind3geburtsdatum"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind3geburtsdatum')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Adresse:</div>
                                <input type="text" id="geschwisterkind3strasse"
                                       formControlName="geschwisterkind3strasse"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind3strasse')"
                                />,
                                <input type="text" id="geschwisterkind3plz"
                                       formControlName="geschwisterkind3plz"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind3plz')"
                                />
                                <input type="text" id="geschwisterkind3ort"
                                       formControlName="geschwisterkind3ort"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind3ort')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Schule:</div>
                                <input type="text" id="geschwisterkind3schule"
                                       formControlName="geschwisterkind3schule"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind3schule')"
                                />
                              </span>
                          </div>
                        </div>
                        <!--ende geschwisterkind3-->

                        <!--anfang geschwisterkind4-->
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geschwisterkind4', fieldConfig)?.visible"
                             class="form-group group-for-geschwisterkind4 col mt-0 mb-3"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="geschwisterkind4"
                                                 [customFormGroup]="antragTicketForm"
                                                 placeholder="-"
                                                 [items]="schuelerList"
                                                 bindLabel="fullName"
                                                 [multiple]="false"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('geschwisterkind4','STANDARD.GESCHWISTERKIND4')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.GESCHWISTERKIND4_INFO,'TICKETDETAIL.GESCHWISTERKIND_INFO')"
                                                 [readonly]="readonly"
                                                 (onchange) = setGeschwisterkind(4)
                                                 data-cy="geschwisterkind4"
                              ></app-custom-select>
                            </div>
                          </div>

                          <div *ngIf=" !(!getFormItem('geschwisterkind4').value) && fieldConfigService.getFieldFromFieldConfig('geschwisterkind4', fieldConfig)?.visible"
                               class="row"
                          >
                              <span>
                                <div class="geschwisterInput-label">Name:</div>
                                <input id="geschwisterkind4name" type="text"
                                       formControlName="geschwisterkind4name"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind4name')"
                                />,
                                <input id="geschwisterkind4vorname" type="text"
                                       formControlName="geschwisterkind4vorname"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind4vorname')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Geburtsdatum:</div>
                                <input id="geschwisterkind4geburtsdatum" type="text"
                                       formControlName="geschwisterkind4geburtsdatum"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind4geburtsdatum')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Adresse:</div>
                                <input type="text" id="geschwisterkind4strasse"
                                       formControlName="geschwisterkind4strasse"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind4strasse')"
                                />,
                                <input type="text" id="geschwisterkind4plz"
                                       formControlName="geschwisterkind4plz"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind4plz')"
                                />
                                <input type="text" id="geschwisterkind4ort"
                                       formControlName="geschwisterkind4ort"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind4ort')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Schule:</div>
                                <input type="text" id="geschwisterkind4schule"
                                       formControlName="geschwisterkind4schule"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind4schule')"
                                />
                              </span>
                          </div>
                        </div>
                        <!--ende geschwisterkind4-->

                        <!--anfang geschwisterkind5-->
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geschwisterkind5', fieldConfig)?.visible"
                             class="form-group group-for-geschwisterkind5 col mt-0 mb-3"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="geschwisterkind5"
                                                 [customFormGroup]="antragTicketForm"
                                                 placeholder="-"
                                                 [items]="schuelerList"
                                                 bindLabel="fullName"
                                                 [multiple]="false"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('geschwisterkind5','STANDARD.GESCHWISTERKIND5')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.GESCHWISTERKIND5_INFO,'TICKETDETAIL.GESCHWISTERKIND_INFO')"
                                                 [readonly]="readonly"
                                                 (onchange) = setGeschwisterkind(5)
                                                 data-cy="geschwisterkind5"
                              ></app-custom-select>
                            </div>
                          </div>

                          <div *ngIf=" !(!getFormItem('geschwisterkind5').value) && fieldConfigService.getFieldFromFieldConfig('geschwisterkind5', fieldConfig)?.visible"
                               class="row"
                          >
                              <span>
                                <div class="geschwisterInput-label">Name:</div>
                                <input id="geschwisterkind5name" type="text"
                                       formControlName="geschwisterkind5name"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind5name')"
                                />,
                                <input id="geschwisterkind5vorname" type="text"
                                       formControlName="geschwisterkind5vorname"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind5vorname')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Geburtsdatum:</div>
                                <input id="geschwisterkind5geburtsdatum" type="text"
                                       formControlName="geschwisterkind5geburtsdatum"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind5geburtsdatum')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Adresse:</div>
                                <input type="text" id="geschwisterkind5strasse"
                                       formControlName="geschwisterkind5strasse"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind5strasse')"
                                />,
                                <input type="text" id="geschwisterkind5plz"
                                       formControlName="geschwisterkind5plz"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind5plz')"
                                />
                                <input type="text" id="geschwisterkind5ort"
                                       formControlName="geschwisterkind5ort"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind5ort')"
                                />
                              </span>
                            <br>
                            <span>
                                <div class="geschwisterInput-label">Schule:</div>
                                <input type="text" id="geschwisterkind5schule"
                                       formControlName="geschwisterkind5schule"
                                       class="geschwisterInput" readonly="readonly"
                                       [style]="dynamicWidth('geschwisterkind5schule')"
                                />
                              </span>
                          </div>
                        </div>
                        <!--ende geschwisterkind5-->

                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('bezugAsylbewerberleistungsgesetz', fieldConfig)?.visible"
                             class="form-group group-for-bezugAsylbewerberleistungsgesetz col mt-0 mb-3"
                        >
                          <div class="d-flex flex-row"
                            [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                            <label class="checkbox-container">{{fieldConfigService.getFieldLabel('bezugAsylbewerberleistungsgesetz', fieldConfig, 'TICKETDETAIL.BEZUG_ASYLBEWERBERLEISTUNGSGESETZ' | translate)}}
                              <input type="checkbox" class="control control-checkbox custom-control-input"
                                     formControlName="bezugAsylbewerberleistungsgesetz"
                                     id="bezugAsylbewerberleistungsgesetz"
                                     name="bezugAsylbewerberleistungsgesetz"
                              >
                              <span class="checkmark" data-cy="bezugAsylbewerberleistungsgesetz"></span>
                            </label>
                            <fa-icon *ngIf="getTextbausteinOrTranslate(KeyString.ASYL_INFO,'STANDARD.ASYL_INFO')"
                              class="infoIcon"
                                     [icon]="faInfo"
                                     title="{{getTextbausteinOrTranslate(KeyString.ASYL_INFO,'STANDARD.ASYL_INFO')}}"
                            ></fa-icon>
                          </div>
                        </div>
                        <div *ngIf="getFormItem('bezugAsylbewerberleistungsgesetz')?.value === true && fieldConfigService.getFieldFromFieldConfig('bezugNachweis', fieldConfig)?.visible"
                             class="form-group group-for-bezugNachweis col mt-0 mb-0"
                        >
                          <div *ngIf="!readonly" class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="bezugNachweis"
                                                [customFormGroup]="antragTicketForm"
                                                name="bezugNachweis"
                                                [type]="'file'"
                                                [clearable]="true"
                                                [acceptFormats]="allowedNachweisFormats"
                                                [label]="getFieldLabelOrTranslate('bezugNachweis','TICKETDETAIL.BEZUG_NACHWEIS')"
                                                [readonly]="readonly"
                                                (onchange)="handleNachweis($event)"
                                                (onClear)="deleteNachweis('bezugNachweis')"
                                                data-cy="bezugNachweis"
                              ></app-custom-input>
                            </div>
                          </div>
                          <div *ngIf="!(!bezugNachweis)"
                               class="d-flex flex-row"
                          >
                            <div class="flex-grow-1 mr-2">
                              <label *ngIf="readonly" for="bezugNachweisThumb">
                                {{ fieldConfigService.getFieldLabel('bezugNachweis', fieldConfig, "TICKETDETAIL.BEZUG_NACHWEIS" | translate) }}
                              </label>
                              <div class="img-thumbnail large mr-2" id="bezugNachweisThumb">
                                <img *ngIf="!bezugNachweisIsPDF"
                                     data-ot-ignore
                                     (click)="previewImg(bezugNachweis)"
                                     [src]="sanitize(bezugNachweis)"
                                     alt="img"
                                     data-cy="bezugNachweisThumb"/>
                                <pdf-viewer *ngIf="bezugNachweisIsPDF"
                                            [src]="bezugNachweis"
                                            [render-text]="false"
                                            [original-size]="false"
                                            [show-borders]="false"
                                            [show-all]="false"
                                            [zoom]=".9"
                                            [zoom-scale]="'page-fit'"
                                            [style]="'width:100px;height:120px;'"
                                            (click)="previewPDF(bezugNachweis)"
                                ></pdf-viewer>
                              </div>
                              <fa-icon *ngIf="!!this.antragTicket"
                                       [icon]=faDelete
                                       title="{{'APPLICATION.BUTTON_DELETE'|translate}}"
                                       class="fa-l custom-card-btn-rejectbeleg"
                                       (click)="deleteNachweis('bezugNachweis')"
                              ></fa-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende kostenberechnung info-->
                    </div>
                    <!--ende linke Seite-->

                    <!--RECHTE SEITE-->
                    <div class="col-md-6">

                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('wegtyp', fieldConfig)?.visible"
                           class="form-group group-for-wegtyp col mt-0 mb-0"
                      >
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-select id="wegtyp"
                                               [customFormGroup]="antragTicketForm"
                                               placeholder="-"
                                               [multiple]="false"
                                               [clearable]="false"
                                               [items]="optionWegtyp"
                                               bindLabel="name"
                                               bindValue="id"
                                               [label]="getFieldLabelOrTranslate('wegtyp','TICKETDETAIL.WEGTYP.TITEL')"
                                               [infoText]="getTextbausteinOrTranslate(KeyString.WEGTYP_INFO,'STANDARD.WEGTYP_INFO')"
                                               [readonly]="readonly"
                                               data-cy="wegtyp"
                            ></app-custom-select>
                          </div>
                        </div>
                      </div>

                      <!--anfang SCHULE-->
                      <div class="flex-row sub-paragraph">
                        <h4 class="mt-1 mb-3">
                          {{getTextbausteinOrTranslate(KeyString.ANGABEN_SCHULE_TITEL_TICKET,'STANDARD.ANGABEN_SCHULE')}}
                        </h4>
                        <div class="form-group group-for-schule col mt-0 mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="schule"
                                                 [customFormGroup]="antragTicketForm"
                                                 [placeholder]="(!this.antragTicket||this.antragTicket?.status == draftStatus)?'-':this.antragTicket?.schule?.toString()"
                                                 [items]="schuleList"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('schule','STANDARD.SCHULE')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.SCHULE_INFO,'')"
                                                 [readonly]="readonly"
                                                 data-cy="schule"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div  *ngIf="getFormItem('isBerufskolleg')?.value===true &&
                                fieldConfigService.getFieldFromFieldConfig('bildungsgang', fieldConfig)?.visible">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2"
                                 style="margin-top:-15px;margin-bottom:1ch;"
                                 [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                              <label class="checkbox-container" style="margin-bottom:2ch;">
                                {{getFieldLabelOrTranslate('isBerufskolleg', 'STANDARD.BERUFSKOLLEG')}}
                                <input type="checkbox" class="control control-checkbox custom-control-input"
                                       formControlName="isBerufskolleg"
                                       id="isBerufskolleg"
                                       name="isBerufskolleg"
                                       onclick="return false;"
                                       checked
                                >
                                <span class="checkmark" [attr.data-cy]="'isBerufskolleg'"></span>
                              </label>
                              <app-custom-select  *ngIf="effectivelyVisible.includes('bildungsgang')"
                                                  id="bildungsgang"
                                                  [customFormGroup]="antragTicketForm"
                                                  [placeholder]="!getFormItem('schule')?.value?('TICKETDETAIL.INFO_KEINE_SCHULE' |translate):'-'"
                                                  [items]="filteredBildungsgangList"
                                                  [multiple]="false"
                                                  [clearable]="false"
                                                  bindLabel="name"
                                                  bindValue="id"
                                                  [label]="getFieldLabelOrTranslate('bildungsgang','STANDARD.BILDUNGSGANG')"
                                                  [infoText]="getTextbausteinOrTranslate(KeyString.BILDUNGSGANG_INFO,'')"
                                                  [infoTextBelowInput]="getBildungsgangInfotextBelowInput()"
                                                  [readonly]="readonly || !getFormItem('schule')?.value"
                                                  data-cy="bildungsgang"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="effectivelyVisible.includes('bildungsgangdatum') && fieldConfigService.getFieldFromFieldConfig('bildungsgangdatum', fieldConfig)?.visible"
                             class="form-group group-for-bildungsgangdatum col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="bildungsgangdatum"
                                                type="date"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('bildungsgangdatum','STANDARD.BILDUNGSGANG_DATUM')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.BILDUNGSGANGDATUM_INFO,'')"
                                                [readonly]="readonly"
                                                data-content="dd.mm.yyyy"
                                                [min]="this.minDate | date:'yyyy-MM-dd'"
                                                data-cy="bildungsgangdatum"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('fachbereich') && fieldConfigService.getFieldFromFieldConfig('fachbereich', fieldConfig)?.visible"
                             class="form-group group-for-fachbereich col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="fachbereich"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('fachbereich','STANDARD.FACHBEREICH')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.FACHBEREICH_INFO,'')"
                                                [readonly]="readonly"
                                                data-cy="fachbereich"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="getFormItem('schule').value && fieldConfigService.getFieldFromFieldConfig('schuljahr', fieldConfig)?.visible"
                             class="form-group group-for-schuljahr col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="schuljahr"
                                                 [customFormGroup]="antragTicketForm"
                                                 [placeholder]="!getFormItem('schule')?.value?('TICKETDETAIL.INFO_KEINE_SCHULE' |translate):'-'"
                                                 [items]="filteredSchuljahreList"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('schuljahr','STANDARD.SCHULJAHR')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.SCHULJAHR_INFO,'')"
                                                 [readonly]="readonly || !getFormItem('schule')?.value"
                                                 data-cy="schuljahr"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="getFormItem('isBerufskolleg').value===false && fieldConfigService.getFieldFromFieldConfig('klasse', fieldConfig)?.visible"
                             class="form-group group-for-schuljahr col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="klasse"
                                                 [customFormGroup]="antragTicketForm"
                                                 [placeholder]="!getFormItem('schule')?.value?('TICKETDETAIL.INFO_KEINE_SCHULE' |translate):'-'"
                                                 [items]="filteredKlassenList"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('klasse','STANDARD.SCHULKLASSE')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.KLASSE_INFO,'')"
                                                 [readonly]="readonly || !getFormItem('schule')?.value"
                                                 data-cy="klasse"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="getFormItem('wegtyp')?.value === wegtypBEIDES && fieldConfigService.getFieldFromFieldConfig('schultageJeWoche', fieldConfig)?.visible"
                             class="form-group group-for-schultageJeWoche col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <label for="schultageJeWoche">
                                {{getFieldLabelOrTranslate('schultageJeWoche', 'TICKETDETAIL.PRAKTIKUM.SCHULTAGE_JE_WOCHE')}}
                              </label>
                              <br>
                              <input id="schultageJeWoche"
                                     type="number"
                                     min="0" max="7"
                                     class="form-control"
                                     formControlName="schultageJeWoche"
                                     [readonly]="readonly"
                                     data-cy="schultageJeWoche"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende SCHULE-->

                      <!--Anfang Ticket-->
                      <div class="flex-row sub-paragraph">
                        <h4 class="mt-1 mb-3">
                          {{getTextbausteinOrTranslate(KeyString.ANGABEN_TICKET_TITEL_TICKET,'TICKETDETAIL.ANGABEN_TICKET_TITEL_TICKET')}}
                        </h4>

                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('abonnementnummerAntrag', fieldConfig)?.visible"
                             class="form-group group-for-abonnementnummerAntrag col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="abonnementnummerAntrag"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('abonnementnummerAntrag','TICKETDETAIL.ABONNEMENTNUMMER')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.ABONNEMENTNUMMER_INFO,'TICKETDETAIL.ABONNEMENTNUMMER_INFO')"
                                                [readonly]="readonly"
                                                [noteText]="'Gültige Formate: 7#####, 7######, 7#####-0#, 7######-0#, 7#####-#, 7######-#'"
                                                data-cy="abonnementnummerAntrag"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('aboGueltigkeit', fieldConfig)?.visible"
                             class="form-group group-for-aboGueltigkeit col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">

                              <app-custom-input id="aboGueltigkeit"
                                                [type]="this.isMonthSelection? 'month' : 'date'"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('aboGueltigkeit','TICKETDETAIL.ABO_GUELTIGKEIT')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.ABO_GUELTIG_AB_INFO,'TICKETDETAIL.ABO_GUELTIG_AB_INFO')"
                                                [noteText]="this.gueltigAbNoteText"
                                                [readonly]="this.disableGueltigAb || readonly"
                                                data-content="dd.mm.yyyy"
                                                [min]="this.minDateTicketGueltigAb | date:this.isMonthSelection? 'yyyy-MM' : 'yyyy-MM-dd'"
                                                [max]="this.maxDateTicketGueltigAb | date:this.isMonthSelection? 'yyyy-MM' : 'yyyy-MM-dd'"
                                                [pattern]="this.isMonthSelection? '[0-9]{4}-[0-9]{2}' : '[0-9]{4}-[0-9]{2}-[0-9]{2}'"
                                                [placeholder]="this.isMonthSelection? 'yyyy-MM' : 'yyyy-MM-dd'"
                                                data-cy="aboGueltigkeit"
                              ></app-custom-input>
                              <h6 class="text-warning" *ngIf="this.isMonthSelection
                                                              && fieldConfigService.getFieldFromFieldConfig('aboGueltigkeitWarntext', fieldConfig)?.visible">{{this.gueltigAbInfoText}}</h6>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('ticket', fieldConfig)?.visible"
                             class="form-group group-for-ticket col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="ticket"
                                                 class="flex-grow-1"
                                                 [customFormGroup]="antragTicketForm"
                                                 [placeholder]="!getFormItem('schule')?.value? ('TICKETDETAIL.INFO_KEINE_SCHULE' |translate) :'-'"
                                                 [items]="filteredTicketList"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('ticket','TICKETDETAIL.TICKETART')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.TICKET_INFO,'TICKETDETAIL.TICKETART-INFO')"
                                                 [readonly]="readonly || !getFormItem('schule')?.value"
                                                 data-cy="ticket"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>

                      </div>
                      <!--Ende Ticket -->

                      <!--PRAKTIKUM-->
                      <div *ngIf="getFormItem('wegtyp')?.value === wegtypPRAKTIKUMSWEG || getFormItem('wegtyp')?.value === 'BEIDES'"
                           class="flex-row sub-paragraph"
                      > <!-- group for Praktikum -->
                        <h4>{{'TICKETDETAIL.PRAKTIKUM.TITEL' |translate }}</h4>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumHinweis', fieldConfig)?.visible"
                             class="form-group group-for-praktikumHinweis col mt-2">
                          <div class="d-flex flex-row" >
                            <div class="flex-grow-1 mr-2">
                              <label for="praktikumHinweis">
                                {{getFieldLabelOrTranslate('praktikumHinweis', 'TICKETDETAIL.PRAKTIKUM.HINWEIS')}}
                              </label>
                              <div class="alert alert-warning" id="praktikumHinweis">
                                {{getTextbausteinOrTranslate(KeyString.ANTRAGTICKET_PRAKTIKUM_HINWEIS,'STANDARD.PRAKTIKUM_INFO')}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumStelle', fieldConfig)?.visible"
                             class="form-group group-for-name col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumStelle"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('praktikumStelle','FAHRKOSTEN-DETAIL.PRAKTIKUMSTELLE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMSTELLE_INFO,'STANDARD.PRAKTIKUMSTELLE_INFO')"
                                                [readonly]="readonly"
                                                data-cy="praktikumStelle"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumStrasse', fieldConfig)?.visible"
                             class="form-group group-for-praktikumStrasse col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumStrasse"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('praktikumStrasse','STANDARD.STRASSE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMSTRASSE_INFO,'')"
                                                [readonly]="readonly"
                                                noteText="Bitte geben Sie die Straße und die Hausnummer an."
                                                data-cy="praktikumStrasse"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumPlz', fieldConfig)?.visible"
                             class="form-group group-for-praktikumPlz col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumPlz"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('praktikumPlz','STANDARD.PLZ')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMPLZ_INFO,'')"
                                                [readonly]="readonly"
                                                [maxlength]="getMaxLength('praktikumPlz')"
                                                [pattern]="getPattern('praktikumPlz')"
                                                (onchange)="setOrt($event, 'praktikumOrt')"
                                                data-cy="praktikumPlz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumOrt', fieldConfig)?.visible"
                             class="form-group group-for-praktikumOrt col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumOrt"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('praktikumOrt','STANDARD.ORT')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMORT_INFO,'')"
                                                [readonly]="readonly"
                                                data-cy="praktikumOrt"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumAdresszusatz', fieldConfig)?.visible"
                             class="form-group group-for-praktikumAdresszusatz col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumAdresszusatz"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('praktikumAdresszusatz','STANDARD.ADRESSZUSATZ')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMADRESSZUSATZ_INFO,'STANDARD.ADRESSZUSATZ_INFO')"
                                                [readonly]="readonly"
                                                data-cy="praktikumAdresszusatz"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumLand', fieldConfig)?.visible"
                             class="form-group group-for-praktikumLand col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select
                                id="praktikumLand"
                                [customFormGroup]="antragTicketForm"
                                [items]="landList"
                                [multiple]="false"
                                [clearable]="false"
                                bindLabel="value"
                                bindValue="name"
                                [label]="getFieldLabelOrTranslate('praktikumLand','STANDARD.LAND')"
                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMLAND_INFO,'STANDARD.LAND_INFO')"
                                [readonly]="readonly"
                                (onchange)="this.setPLZValidatorForControl('praktikumPlz')"
                                data-cy="praktikumLand"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumStartdatum', fieldConfig)?.visible"
                             class="form-group group-for-praktikumStartdatum col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumStartdatum"
                                                type="date"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('praktikumStartdatum','TICKETDETAIL.PRAKTIKUM.STARTDATUM')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMSTARTDATUM_INFO,'')"
                                                [readonly]="readonly"
                                                data-content="dd.mm.yyyy"
                                                [min]="this.minDate | date:'yyyy-MM-dd'"
                                                data-cy="praktikumStartdatum"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumEnddatum', fieldConfig)?.visible"
                             class="form-group group-for-praktikumEnddatum col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row" >
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumEnddatum"
                                                type="date"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('praktikumEnddatum','TICKETDETAIL.PRAKTIKUM.ENDDATUM')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMENDDATUM_INFO,'')"
                                                [readonly]="readonly"
                                                data-content="dd.mm.yyyy"
                                                [min]="this.minDatePraktikumEnddatum | date:'yyyy-MM-dd'"
                                                data-cy="praktikumEnddatum"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumstageJeWoche', fieldConfig)?.visible"
                             class="form-group group-for-praktikumstageJeWoche col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <label for="praktikumstageJeWoche">
                                {{getFieldLabelOrTranslate('praktikumstageJeWoche', 'TICKETDETAIL.PRAKTIKUM.PRAKTIKUMSTAGE_JE_WOCHE')}}
                              </label>
                              <fa-icon *ngIf="getTextbausteinOrTranslate(KeyString.PRAKTIKUMSTAGE_JE_WOCHE_INFO,'TICKETDETAIL.PRAKTIKUMSVERTRAG-INFO')"
                                class="infoIcon"
                                       [icon]="faInfo"
                                       title="{{getTextbausteinOrTranslate(KeyString.PRAKTIKUMSTAGE_JE_WOCHE_INFO,'TICKETDETAIL.PRAKTIKUMSVERTRAG-INFO')}}"
                              ></fa-icon>
                              <br>
                              <input id="praktikumstageJeWoche" type="number" min="0" max="7" class="form-control"
                                     formControlName="praktikumstageJeWoche" [readonly]="readonly"
                                     data-cy="praktikumstageJeWoche"/>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('praktikumsvertrag', fieldConfig)?.visible"
                             class="form-group group-for-praktikumsvertrag col mt-0 mb-0"
                        >
                          <div *ngIf="!readonly" class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="praktikumsvertrag"
                                                [customFormGroup]="antragTicketForm"
                                                name="praktikumsvertrag"
                                                [type]="'file'"
                                                [clearable]="true"
                                                [label]="getFieldLabelOrTranslate('praktikumsvertrag','TICKETDETAIL.PRAKTIKUM.PRAKTIKUMSVERTRAG')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PRAKTIKUMSVERTRAG_INFO,'')"
                                                [readonly]="readonly"
                                                [acceptFormats]="allowedNachweisFormats"
                                                (onchange)="handleNachweis($event)"
                                                (onClear)="deleteNachweis('praktikumsvertrag')"
                                                data-cy="praktikumsvertrag"
                              ></app-custom-input>
                            </div>
                          </div>
                          <div *ngIf="!(!praktikumsvertrag)"
                               class="d-flex flex-row mt-0"
                          >
                            <div class="flex-grow-1 mr-2">
                              <label *ngIf="readonly" for="praktikumsvertragThumb">
                                {{ fieldConfigService.getFieldLabel('praktikumsvertrag', fieldConfig, "TICKETDETAIL.PRAKTIKUM.PRAKTIKUMSVERTRAG" | translate) }}
                              </label>
                              <div class="img-thumbnail large mr-2"
                                   id="praktikumsvertragThumb">
                                <img *ngIf="!praktikumsvertragIsPDF"
                                     data-ot-ignore
                                     [src]="sanitize(praktikumsvertrag)"
                                     (click)="previewImg(praktikumsvertrag)"
                                     alt="img"
                                     data-cy="praktikumsvertragThumb"/>
                                <pdf-viewer *ngIf="praktikumsvertragIsPDF"
                                            [src]="praktikumsvertrag"
                                            [render-text]="false"
                                            [original-size]="false"
                                            [fit-to-page]="false"
                                            [show-borders]="false"
                                            [show-all]="false"
                                            [zoom]="0.9"
                                            [zoom-scale]="'page-fit'"
                                            [style]="'width:100px;height:120px;'"
                                            (click)="previewPDF(praktikumsvertrag)"
                                ></pdf-viewer>
                              </div>
                              <fa-icon *ngIf="!!this.antragTicket"
                                       [icon]=faDelete
                                       title="{{'APPLICATION.BUTTON_DELETE'|translate}}"
                                       class="fa-l custom-card-btn-rejectbeleg"
                                       (click)="deleteNachweis('praktikumsvertrag')"
                              ></fa-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende PRAKTIKUM-->

                      <!--anfang: Fahrweg-Angaben-->
                      <div class="flex-row sub-paragraph" *ngIf="fieldConfigService.getFieldFromFieldConfig('einstieghaltestelle', fieldConfig)?.visible || fieldConfigService.getFieldFromFieldConfig('ausstieghaltestelleHinweg', fieldConfig)?.visible">
                        <h4 class="mt-1 mb-3">
                          {{getTextbausteinOrTranslate(KeyString.ANGABEN_FAHRWEG_TITEL_TICKET,'TICKETDETAIL.ANGABEN_FAHRWEG')}}
                        </h4>

                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('einstieghaltestelle', fieldConfig)?.visible"
                             class="form-group group-for-einstieghaltestelle col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="einstieghaltestelle"
                                                 [customFormGroup]="antragTicketForm"
                                                 [placeholder]="'Bitte mindestens 2 Zeichen eingeben'"
                                                 [items]="einstiegHaltestelleList"
                                                 bindLabel="comboName"
                                                 bindValue="id"
                                                 [multiple]="false"
                                                 [clearable]="true"
                                                 [searchable]="true"
                                                 [notfoundtext]="'Nichts, gefunden. Bitte mindestens 2 Zeichen eingeben'"
                                                 [label]="getFieldLabelOrTranslate('einstieghaltestelle','TICKETDETAIL.EINSTIEGHALTE')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.EINSTIEGHALTESTELLE_INFO,'')"
                                                 [readonly]="readonly"
                                                 [loading]="this.einstiegHaltestelleLoading"
                                                 (onsearch)="this.filterEinstieghaltestelle()"
                                                 (onScrollToEnd)="this.loadNextPageEinstiegHaltestelle()"
                                                 data-cy="einstieghaltestelle"
                              ></app-custom-select>
                            </div>
                            <div>
                            <span data-original-title="Im Textbaustein bearbeiten" data-placement="top" rel="tooltip"
                                  class="fa fa-info-circle" href="javascript:void(0);"></span>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('ausstieghaltestelleHinweg', fieldConfig)?.visible"
                             class="form-group group-for-ausstieghaltestelleHinweg col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="ausstieghaltestelleHinweg"
                                                 [customFormGroup]="antragTicketForm"
                                                 [placeholder]="!getFormItem('schule')?.value?('TICKETDETAIL.INFO_KEINE_SCHULE' |translate):'Bitte mindestens 2 Zeichen eingeben'"
                                                 [items]="ausstiegHaltestelleList"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="comboName"
                                                 bindValue="id"
                                                 [label]="getFieldLabelOrTranslate('ausstieghaltestelleHinweg','TICKETDETAIL.AUSSTIEGHALTE')"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.AUSSTIEGHALTESTELLE_INFO,'')"
                                                 [readonly]="readonly || !getFormItem('schule')?.value"
                                                 data-cy="ausstieghaltestelleHinweg"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--ende: fahrweg-->


                      <!--SOnderfall-->
                      <div class="flex-row sub-paragraph" *ngIf="effectivelyVisible.includes('sonderfall')">
                        <h4 class="mt-1 mb-3">
                          {{getTextbausteinOrTranslate(KeyString.ERSTATTUNG_GRUENDE_TITEL_TICKET,'TICKETDETAIL.SONDERFALL.TITEL')}}
                        </h4>

                        <div *ngIf="effectivelyVisible.includes('sonderfall')"
                             class="form-group group-for-sonderfall col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-radio id="sonderfall"
                                                [options]="optionSonderfall"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="getFieldLabelOrTranslate('sonderfall','TICKETDETAIL.SONDERFALL.SONDERFAELLE')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.SONDERFALL_INFO,'STANDARD.SONDERFALL_INFO')"
                                                [infoTextBelowInput]="getInfoTextbausteinOrTranslate(KeyString.SONDERFALL_INFO_BELOW,'')"
                                                [readonly]="readonly"
                                                [ngClass]="{'form-check-input-disabled' : readonly, 'form-check-input-active' : !readonly}"
                                                data-cy="sonderfall"
                              ></app-custom-radio>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('sonderfallBegruendung')"
                             class="form-group group-for-sonderfallBegruendung col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-textarea
                                id="sonderfallBegruendung"
                                [rows]="5"
                                [customFormGroup]="antragTicketForm"
                                [label]="getFieldLabelOrTranslate('sonderfallBegruendung','TICKETDETAIL.SONDERFALL.BEGRUENDUNG')"
                                [infoText]="getTextbausteinOrTranslate(KeyString.SONDERFALLBEGRUENDUNG_INFO,'')"
                                [readonly]="readonly"
                                data-cy="sonderfallBegruendung"
                              ></app-textarea>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="effectivelyVisible.includes('sonderfallNachweis')"
                             class="form-group group-for-sonderfallNachweis col mt-0 mb-0"
                        >
                          <div *ngIf="!readonly"
                               class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="sonderfallNachweis"
                                                [customFormGroup]="antragTicketForm"
                                                [type]="'file'"
                                                [clearable]="true"
                                                [acceptFormats]="allowedNachweisFormats"
                                                [label]="getFieldLabelOrTranslate('sonderfallNachweis','TICKETDETAIL.SONDERFALL.NACHWEIS')"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.SONDERFALLNACHWEIS_INFO,'')"
                                                [readonly]="readonly"
                                                (onchange)="handleNachweis($event)"
                                                (onClear)="deleteNachweis('sonderfallNachweis')"
                                                data-cy="sonderfallNachweis"
                              ></app-custom-input>
                            </div>
                          </div>
                          <div *ngIf="!(!sonderfallNachweis)"
                               class="d-flex flex-row mt-2">
                            <div class="flex-grow-1 mr-2">
                              <label *ngIf="readonly" for="sonderfallNachweisThumb">
                                {{ fieldConfigService.getFieldLabel('sonderfallNachweis', fieldConfig, "TICKETDETAIL.SONDERFALL.NACHWEIS" | translate) }}
                              </label>
                              <div class="img-thumbnail large mr-2" id="sonderfallNachweisThumb">
                                <img *ngIf="!sonderfallNachweisIsPDF"
                                     data-ot-ignore
                                     (click)="previewImg(sonderfallNachweis)"
                                     [src]="sanitize(sonderfallNachweis)"
                                     alt="img"
                                     data-cy="sonderfallNachweisThumb"/>
                                <pdf-viewer *ngIf="sonderfallNachweisIsPDF"
                                            [src]="sonderfallNachweis"
                                            [render-text]="false"
                                            [original-size]="false"
                                            [show-borders]="false"
                                            [show-all]="false"
                                            [zoom]=".9"
                                            [zoom-scale]="'page-fit'"
                                            [style]="'width:100px;height:120px;'"
                                            (click)="previewPDF(sonderfallNachweis)"
                                ></pdf-viewer>
                              </div>
                              <fa-icon *ngIf="!!this.antragTicket"
                                       [icon]=faDelete
                                       title="{{'APPLICATION.BUTTON_DELETE'|translate}}"
                                       class="fa-l custom-card-btn-rejectbeleg"
                                       (click)="deleteNachweis('sonderfallNachweis')"
                              ></fa-icon>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('referenzFuerZahlungen', fieldConfig)?.visible
                              && antragTicket?.status !== 'ENTWURF' && antragTicket?.status !== 'EINGEREICHT'
                              && antragTicketId && antragTicketId !== ''
                              && antragTicketId !== null"
                           class="col mt-0 mb-0"
                      >
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="referenzFuerZahlungen"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="fieldConfigService.getFieldLabel('referenzFuerZahlungen', fieldConfig, 'TICKETDETAIL.REFERENZZAHLUNG' | translate)"
                                              [infoText]="getTextbausteinOrTranslate(KeyString.REFERENZZAHLUNG_INFO,'')"
                                              [readonly]="true"
                                              data-cy="referenzFuerZahlungen"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('bemerkungAntragsteller', fieldConfig)?.visible"
                           class="form-group group-for-bemerkungAntragsteller col mt-0 mb-0"
                      >
                        <div class="d-flex flex-row">
                          <div class="flex-grow-1 mr-2">
                            <app-textarea
                              label="{{'TICKETDETAIL.BEMANTRAGSTELLER' |translate }}"
                              [rows]="5"
                              [customFormGroup]="antragTicketForm"
                              id="bemerkungAntragsteller"
                              [infoText]="getTextbausteinOrTranslate(KeyString.BEMERKUNG_ANTRAGSTELLER_INFO,'TICKETDETAIL.BEMANTRAGSTELLER-INFO')"
                              [readonly]="readonly"
                              data-cy="bemerkungAntragsteller"
                            ></app-textarea>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('einverstaendnisErklaerung', fieldConfig)?.visible"
                           class="form-group group-for-einverstaendnisErklaerung col mt-0 mb-3"
                      >
                        <div class="custom-control-inline custom-checkbox group-for-einverstaendnisErklaerung d-flex flex-row"
                             [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                          <label class="checkbox-container">
                            {{getFieldLabelOrTranslate('einverstaendnisErklaerung','TICKETDETAIL.EINVERSTAENDNISERKLAERUNG')}}
                            <input type="checkbox" class="control control-checkbox custom-control-input"
                                   formControlName="einverstaendnisErklaerung"
                                   id="einverstaendnisErklaerung"
                                   name="einverstaendnisErklaerung"
                            >
                            <span class="checkmark" data-cy="einverstaendnisErklaerung"></span>
                          </label>
                          <fa-icon *ngIf="getTextbausteinOrTranslate(KeyString.EINVERSTAENDNISERKLAERUNG_INFO,'TICKETDETAIL.EINVERSTAENDNIS_INFO')"
                            class="infoIcon"
                                   [icon]="faInfo"
                                   title="{{getTextbausteinOrTranslate(KeyString.EINVERSTAENDNISERKLAERUNG_INFO,'TICKETDETAIL.EINVERSTAENDNIS_INFO')}}"
                          ></fa-icon>
                        </div>
                      </div>

                    </div>
                    <!--ende rechte Seite-->
                  </div>

                  <div *ngIf="fieldConfigService.getFieldFromFieldConfig('marketingfreigabe', fieldConfig)?.visible"
                       class="form-group group-for-marketingfreigabe col mt-0 mb-0"
                  >
                    <div class="custom-control-inline custom-checkbox group-for-marketingfreigabe d-flex flex-row"
                         [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                      <label class="checkbox-container">
                        {{getFieldLabelOrTranslate('marketingfreigabe', 'TICKETDETAIL.MARKETINGFREIGABE')}}
                        <input type="checkbox" class="control control-checkbox custom-control-input" formControlName="marketingfreigabe"
                               id="marketingfreigabe"
                               name="marketingfreigabe"
                        >
                        <span class="checkmark" data-cy="marketingfreigabe"></span>
                      </label>
                      <fa-icon *ngIf="getTextbausteinOrTranslate(KeyString.MARKETINGFREIGABE_INFO,'STANDARD.MARKETINGFREIGABE_INFO')"
                        class="infoIcon"
                               [icon]="faInfo"
                               title="{{getTextbausteinOrTranslate(KeyString.MARKETINGFREIGABE_INFO,'STANDARD.MARKETINGFREIGABE_INFO')}}"
                      ></fa-icon>
                    </div>
                  </div>
                  <!--SEPA TEIL-->
                  <hr *ngIf="moduleConfigService.isModuleEnabled('antragTicketSepa')">
                  <div *ngIf="moduleConfigService.isModuleEnabled('antragTicketSepa')" class="row">
                    <legend>
                      {{getTextbausteinOrTranslate(KeyString.ERTEILUNG_SEPA_MANDAT_TITEL_TICKET,'TICKETDETAIL.SEPA.TITEL')}}
                    </legend>
                    <div class="row">
                      <div class="form-group group-for-isAntragstellerNotMandatsgeber col mb-4">
                        <div class="custom-control-inline custom-checkbox group-for-isAntragstellerNotMandatsgeber d-flex flex-row"
                             [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                          <label class="checkbox-container">
                            {{''+fieldConfigService.getFieldLabel('isAntragstellerNotMandatsgeber',
                            fieldConfig, 'TICKETDETAIL.SEPA.ANTRAGSTELLER_NICHT_MANDATSGEBER' | translate)}}
                            <input type="checkbox" class="control control-checkbox custom-control-input"
                                   formControlName="isAntragstellerNotMandatsgeber"
                                   id="isAntragstellerNotMandatsgeber"
                                   name="isAntragstellerNotMandatsgeber"
                                   (change)="sepaChange()"
                                   [attr.disabled]="readonly ? true : null"
                            >
                            <span class="checkmark" data-cy="isAntragstellerNotMandatsgeber"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!--ANTRAGSTELLER != MANDATSGEBER-->
                    <div *ngIf="getFormItem('isAntragstellerNotMandatsgeber')?.value" class="row">

                      <!--SEPA LINKS-->
                      <div class="col-md-6">
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('nameSepa', fieldConfig)?.visible"
                             class="form-group group-for-nameSepa col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="nameSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="fieldConfigService.getFieldLabel('nameSepa', fieldConfig, 'TICKETDETAIL.SEPA.NAME' | translate)"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.NAMESEPA_INFO,'')"
                                                [readonly]="readonly"
                                                data-cy="nameSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('vornameSepa', fieldConfig)?.visible"
                             class="form-group group-for-vornameSepa col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="vornameSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="fieldConfigService.getFieldLabel('vornameSepa', fieldConfig, 'TICKETDETAIL.SEPA.VORNAME' | translate)"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.VORNAMESEPA_INFO,'')"
                                                [readonly]="readonly"
                                                data-cy="vornameSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('strasseSepa', fieldConfig)?.visible"
                             class="form-group group-for-strasseSepa col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="strasseSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="fieldConfigService.getFieldLabel('strasseSepa', fieldConfig, 'TICKETDETAIL.SEPA.STRASSE' | translate)"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.STRASSESEPA_INFO,'')"
                                                [readonly]="readonly"
                                                noteText="Bitte geben Sie die Straße und die Hausnummer an."
                                                data-cy="strasseSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('plzSepa', fieldConfig)?.visible"
                             class="form-group group-for-plzSepa col mt-0 mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="plzSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="fieldConfigService.getFieldLabel('plzSepa', fieldConfig, 'TICKETDETAIL.SEPA.PLZ' | translate)"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.PLZSEPA_INFO,'')"
                                                [readonly]="readonly"
                                                [maxlength]="getMaxLength('plzSepa')"
                                                [pattern]="getPattern('plzSepa')"
                                                (onchange)="setOrt($event, 'ortSepa')"
                                                data-cy="plzSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('ortSepa', fieldConfig)?.visible"
                             class="form-group group-for-ortSepa col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="ortSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="fieldConfigService.getFieldLabel('ortSepa', fieldConfig, 'TICKETDETAIL.SEPA.ORT' | translate)"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.ORTSEPA_INFO,'')"
                                                [readonly]="readonly"
                                                data-cy="ortSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('adresszusatzSepa', fieldConfig)?.visible"
                             class="form-group group-for-adresszusatzSepa col mt-0 mb-0">
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="adresszusatzSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="fieldConfigService.getFieldLabel('adresszusatzSepa', fieldConfig, 'STANDARD.ADRESSZUSATZ' | translate)"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.ADRESSZUSATZSEPA_INFO,'STANDARD.ADRESSZUSATZ_INFO')"
                                                [readonly]="readonly"
                                                data-cy="adresszusatzSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('landSepa', fieldConfig)?.visible"
                             class="form-group group-for-landSepa col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select
                                id="landSepa"
                                [customFormGroup]="antragTicketForm"
                                [items]="landList"
                                [multiple]="false"
                                [clearable]="false"
                                bindLabel="value"
                                bindValue="name"
                                [label]="fieldConfigService.getFieldLabel('landSepa', fieldConfig, 'STANDARD.LAND' | translate)"
                                [infoText]="getTextbausteinOrTranslate(KeyString.LANDSEPA_INFO,'STANDARD.LAND_INFO')"
                                [readonly]="readonly"
                                (onchange)="this.setPLZValidatorForControl('plzSepa')"
                                data-cy="landSepa"
                              ></app-custom-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--SEPA RECHTS-->
                      <div class="col-md-6">
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geburtsdatumSepa', fieldConfig)?.visible"
                             class="form-group group-for-geburtsdatumSepa col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="geburtsdatumSepa"
                                                type="date"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="fieldConfigService.getFieldLabel('geburtsdatumSepa', fieldConfig, 'TICKETDETAIL.SEPA.GEBURTSDATUM' | translate)"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.GEBURTSDATUMSEPA_INFO,'STANDARD.GEBURTSDATUM_KONTO_INFO')"
                                                [readonly]="readonly"
                                                data-content="dd.mm.yyyy"
                                                [max]="this.today | date:'yyyy-MM-dd'"
                                                [min]="this.minDateBirthday | date:'yyyy-MM-dd'"
                                                data-cy="geburtsdatumSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('geschlechtSepa', fieldConfig)?.visible"
                             class="form-group group-for-geschlecht col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-select id="geschlechtSepa"
                                                 class="flex-grow-1"
                                                 [customFormGroup]="antragTicketForm"
                                                 placeholder="-"
                                                 [items]="optionGeschlecht"
                                                 [multiple]="false"
                                                 [clearable]="false"
                                                 bindLabel="name"
                                                 bindValue="id"
                                                 [label]="fieldConfigService.getFieldLabel('geschlechtSepa', fieldConfig, 'STANDARD.GESCHLECHT.TITEL' | translate)"
                                                 [infoText]="getTextbausteinOrTranslate(KeyString.GESCHLECHTSEPA_INFO,'STANDARD.GESCHLECHT_INFO')"
                                                 [readonly]="readonly"
                                                 data-cy="geschlechtSepa">
                              </app-custom-select>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('emailSepa', fieldConfig)?.visible"
                             class="form-group group-for-emailSepa col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="emailSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="fieldConfigService.getFieldLabel('emailSepa', fieldConfig, 'TICKETDETAIL.SEPA.EMAIL' | translate)"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.EMAILSEPA_INFO,'STANDARD.EMAIL_INFO')"
                                                [readonly]="readonly"
                                                data-cy="emailSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('telefonnummerSepa', fieldConfig)?.visible"
                             class="form-group group-for-telefonnummerSepa col mt-0 mb-0"
                        >
                          <div class="d-flex flex-row">
                            <div class="flex-grow-1 mr-2">
                              <app-custom-input id="telefonnummerSepa"
                                                [customFormGroup]="antragTicketForm"
                                                [label]="fieldConfigService.getFieldLabel('telefonnummerSepa', fieldConfig, 'TICKETDETAIL.SEPA.TELEFONNUMMER' | translate)"
                                                [infoText]="getTextbausteinOrTranslate(KeyString.TELEFONNUMMERSEPA_INFO,'STANDARD.TELEFONNUMMER_INFO')"
                                                [readonly]="readonly"
                                                [noteText]="translateService.instant('STANDARD.TELEFONNUMMER_VALIDATION_INFO')"
                                                data-cy="telefonnummerSepa"
                              ></app-custom-input>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('nameKreditinstitutSepa', fieldConfig)?.visible"
                             class="form-group group-for-nameKreditinstitutSepa col mt-0 mb-0"
                        >
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="nameKreditinstitutSepa"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="fieldConfigService.getFieldLabel('nameKreditinstitutSepa', fieldConfig, 'TICKETDETAIL.SEPA.KREDITINSTITUT_SEPA' | translate)"
                                              [infoText]="getTextbausteinOrTranslate(KeyString.KREDITINSTITUTSEPA_INFO,'')"
                                              [readonly]="readonly"
                                              data-cy="nameKreditinstitutSepa"
                            ></app-custom-input>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('ibanSepa', fieldConfig)?.visible"
                             class="form-group group-for-ibanSepa col mt-0 mb-0"
                        >
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="ibanSepa"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="fieldConfigService.getFieldLabel('ibanSepa', fieldConfig, 'TICKETDETAIL.SEPA.IBAN_SEPA' | translate)"
                                              [infoText]="getTextbausteinOrTranslate(KeyString.SEPAIBAN_INFO,'')"
                                              [readonly]="readonly"
                                              data-cy="ibanSepa"
                            ></app-custom-input>
                          </div>
                        </div>
                        <div *ngIf="fieldConfigService.getFieldFromFieldConfig('bicSepa', fieldConfig)?.visible"
                             class="form-group group-for-bicSepa col mt-0 mb-0"
                        >
                          <div class="flex-grow-1 mr-2">
                            <app-custom-input id="bicSepa"
                                              [customFormGroup]="antragTicketForm"
                                              [label]="fieldConfigService.getFieldLabel('bicSepa', fieldConfig, 'TICKETDETAIL.SEPA.BIC_SEPA' | translate)"
                                              [infoText]="getTextbausteinOrTranslate(KeyString.SEPABIC_INFO,'STANDARD.BIC_INFO')"
                                              [readonly]="readonly"
                                              data-cy="bicSepa"
                            ></app-custom-input>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--ANTRAGSTELLER == MANDATSGEBER-->
                    <div *ngIf="!(getFormItem('isAntragstellerNotMandatsgeber')?.value)" class="col-md-6">
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('nameKreditinstitut', fieldConfig)?.visible"
                           class="form-group group-for-nameKreditinstitut col mt-0 mb-0"
                      >
                        <div class="flex-grow-1 mr-2">
                          <app-custom-input id="nameKreditinstitut"
                                            [customFormGroup]="antragTicketForm"
                                            [label]="fieldConfigService.getFieldLabel('nameKreditinstitut', fieldConfig, 'TICKETDETAIL.SEPA.KREDITINSTITUT' | translate)"
                                            [infoText]="getTextbausteinOrTranslate(KeyString.NAMEKREDITINSTITUT_INFO,'')"
                                            [readonly]="readonly"
                                            data-cy="nameKreditinstitut"
                          ></app-custom-input>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('iban', fieldConfig)?.visible"
                           class="form-group group-for-iban col mt-0 mb-0"
                      >
                        <div class="flex-grow-1 mr-2">
                          <app-custom-input id="iban"
                                            [customFormGroup]="antragTicketForm"
                                            [label]="fieldConfigService.getFieldLabel('iban', fieldConfig, 'TICKETDETAIL.SEPA.IBAN' | translate)"
                                            [infoText]="getTextbausteinOrTranslate(KeyString.IBAN_INFO,'')"
                                            [readonly]="readonly"
                                            data-cy="iban"
                          ></app-custom-input>
                        </div>
                      </div>
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('bic', fieldConfig)?.visible"
                           class="form-group group-for-iban col mt-0 mb-0"
                      >
                        <div class="flex-grow-1 mr-2">
                          <app-custom-input id="bic"
                                            [customFormGroup]="antragTicketForm"
                                            [label]="fieldConfigService.getFieldLabel('bic', fieldConfig, 'TICKETDETAIL.SEPA.BIC' | translate)"
                                            [infoText]="getTextbausteinOrTranslate(KeyString.BIC_INFO,'STANDARD.BIC_INFO')"
                                            [readonly]="readonly"
                                            data-cy="bic"
                          ></app-custom-input>
                        </div>
                      </div>
                    </div>
                    <div class="form-group group-for-datenschutz col mb-4 mt-4">
                      <div *ngIf="fieldConfigService.getFieldFromFieldConfig('datenschutz', fieldConfig)?.visible"
                           class="custom-control-inline custom-checkbox group-for-datenschutz d-flex flex-row"
                           [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}"
                      >
                        <label class="checkbox-container" for="datenschutz">
                          <p [innerHTML]="fieldConfigService.getFieldLabel('datenschutz', fieldConfig, 'TICKETDETAIL.SEPA.DATENSCHUTZ' | translate)"></p>
                          <input type="checkbox" class="control control-checkbox custom-control-input" formControlName="datenschutz"
                                 id="datenschutz"
                                 name="datenschutz"
                          >
                          <span class="checkmark" data-cy="datenschutz"></span>
                        </label>
                        <fa-icon *ngIf="getTextbausteinOrTranslate(KeyString.DATENSCHUTZ_INFO,'STANDARD.DATENSCHUTZ_INFO')"
                          class="infoIcon"
                                 [icon]="faInfo"
                                 title="{{getTextbausteinOrTranslate(KeyString.DATENSCHUTZ_INFO,'STANDARD.DATENSCHUTZ_INFO')}}"
                        ></fa-icon>
                      </div>
                    </div>
                  </div>
                  <!-- ende SEPA TEIL-->

                  <!--finally-->
                  <div class="form-group group-for-richtigkeitDerAngaben col mb-4 mt-4">
                    <div *ngIf="fieldConfigService.getFieldFromFieldConfig('richtigkeitDerAngaben', fieldConfig)?.visible"
                         class="custom-control-inline custom-checkbox group-for-richtigkeitDerAngaben d-flex flex-row"
                         [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}"
                    >
                      <label class="checkbox-container" for="richtigkeitDerAngaben">
                        <p [innerHTML]="fieldConfigService.getFieldLabel('richtigkeitDerAngaben', fieldConfig, 'TICKETDETAIL.RICHTIGKEIT_DER_ANGABEN' | translate)"></p>
                        <input type="checkbox" class="control control-checkbox custom-control-input"
                               formControlName="richtigkeitDerAngaben"
                               id="richtigkeitDerAngaben"
                               name="richtigkeitDerAngaben"
                        >
                        <span class="checkmark" data-cy="richtigkeitDerAngaben"></span>
                      </label>
                      <fa-icon *ngIf="getTextbausteinOrTranslate(KeyString.RICHTIGKEIT_DER_ANGABEN_INFO,'TICKETDETAIL.RICHTIGKEIT_DER_ANGABEN_INFO')"
                        class="infoIcon"
                               [icon]="faInfo"
                               title="{{getTextbausteinOrTranslate(KeyString.RICHTIGKEIT_DER_ANGABEN_INFO,'TICKETDETAIL.RICHTIGKEIT_DER_ANGABEN_INFO')}}"
                      ></fa-icon>
                    </div>
                  </div>

                  <div *ngIf="isAntragstellerUnderage===true"
                       class="form-group group-for-zustimmungGesetzlicherVertreter col mb-4 mt-4"
                  >
                    <div *ngIf="fieldConfigService.getFieldFromFieldConfig('zustimmungGesetzlicherVertreter', fieldConfig)?.visible"
                         class="custom-control-inline custom-checkbox group-for-zustimmungGesetzlicherVertreter d-flex flex-row"
                         [ngClass]="{'form-check-disabled' : readonly, 'form-check-active' : !readonly}">
                      <label class="checkbox-container" for="zustimmungGesetzlicherVertreter">
                        <p [innerHTML]="''+(fieldConfigService.getFieldFromFieldConfig('zustimmungGesetzlicherVertreter', fieldConfig)?.required?pflichtFeldMarker:'')+fieldConfigService.getFieldLabel('zustimmungGesetzlicherVertreter', fieldConfig, 'TICKETDETAIL.ZUSTIMMUNG_GESETZLICHER_VERTRETER' | translate)"></p>
                        <p [innerHTML]="fieldConfigService.getFieldLabel('zustimmungGesetzlicherVertreter', fieldConfig, 'TICKETDETAIL.ZUSTIMMUNG_GESETZLICHER_VERTRETER' | translate)"></p>
                        <input type="checkbox" class="control control-checkbox custom-control-input"
                               formControlName="zustimmungGesetzlicherVertreter"
                               id="zustimmungGesetzlicherVertreter"
                               name="zustimmungGesetzlicherVertreter"
                        >
                        <span class="checkmark" data-cy="zustimmungGesetzlicherVertreter"></span>
                      </label>
                    </div>
                  </div>


                  <!--BUTTONS-->
                  <div class="d-flex flex-row-reverse">
                    <input *ngIf="isDeleteDraftButtonVisible" type="button" value="Entwurf löschen"
                           class="btn btn-secondary save btn-block" (click)="deleteDraft()"
                           data-cy="deleteDraft">
                    <span style="width: 1em"></span>
                    <input *ngIf="isUpdateEntwurfButtonVisible" type="button" value="Entwurf speichern"
                           class="btn btn-secondary save btn-block"
                           (click)="saveOrUpdate(draftStatus,'Entwurf wurde erfolgreich gespeichert.', 'ticket-detail')"
                           data-cy="saveOrUpdate">
                    <span style="width: 1em"></span>
                    <input *ngIf="isEinreichenButtonVisible" type="button" value="Antrag einreichen"
                           class="btn btn-primary save btn-block"
                           (click)="saveOrUpdate(eingereichtStatus,'Antrag wurde erfolgreich eingereicht.','ticket-table')"
                           data-cy="antragEinreichen">
                  </div>

                </form>
              </div>
            </div>
            <!--ende: form container-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    [fullScreen] = "true"
  >
  </ngx-spinner>
</main>
