// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: 'v2.1.17',
  production: true, // change locally to false but never commit!!!
  displayTextbausteinKeys: false
};


